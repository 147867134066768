import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Field } from 'formik';
import _ from 'lodash';
import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { ButtonArea } from '../../components';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  LineContainer,
  StyledSelect,
  FirstCell,
  SecondCell,
  ThirdCell,
  HeaderContainer,
  StyledIcon,
  ErrorContainer,
} from './styled';
import { getWorksheetUrl } from '../../helpers/getWorksheetUrl';
import { EmployeesWorksheetSteps, ImportType } from '../../types';
import { useCreateImport } from '../../hooks/useCreateImport';
import {
  CreateImportInput,
  CreateImportPayloadBuilder,
} from '../../helpers/createImportPayloadBuilder';
import { FieldInput, ImportFormBuilder } from '../../helpers/importFormBuilder';
import { generateFileDictionary } from '../../helpers/generateFileDictionary';

interface IDataAssociationProps {
  operation: ImportType;
}

export const DataAssociation = ({ operation }: IDataAssociationProps) => {
  const navigate = useNavigate();
  const state = useLocation() as any;
  const currentState = useMemo(() => state.state, [state]);
  const {
    initialValues,
    validationSchema,
    inputList,
    state: initialState,
  } = new ImportFormBuilder().build({
    simpleImport: currentState.simpleImport,
    operation,
  });

  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [sampleValues, setSampleValues] = useState(
    new Map(Object.entries(initialState)),
  );
  const [contactError, setContactError] = useState(false);
  const { createImportSheet } = useCreateImport();

  const initialCell = useMemo(() => {
    if (currentState.initialCell?.length >= 2) {
      const cellColumn =
        currentState.initialCell
          .replace(/[^A-Za-z]/g, '')
          .toLowerCase()
          .charCodeAt(0) - 96;

      const cellLine = parseInt(currentState.initialCell.replace(/\D/g, ''));
      return {
        cellColumn,
        cellLine,
      };
    }
    return {
      cellColumn: 0,
      cellLine: 0,
    };
  }, [currentState]);

  const dataTable = useMemo(() => {
    const table = Object.assign([], currentState?.dataTable);
    const index = table.indexOf('emptyCell');
    index >= 0 && table.splice(index, 1);

    table.map((e: any) => {
      e.splice(0, initialCell?.cellColumn - 1);
      return e;
    });
    return table;
  }, [currentState]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      if (
        !formik.values.emailField.value &&
        !formik.values.phoneField.value &&
        operation === ImportType.insert
      ) {
        setContactError(true);
      } else {
        setContactError(false);
        const { values } = formik;
        const { hasHeader } = currentState;
        const fileDictionary = generateFileDictionary(values, hasHeader);

        if (operation === ImportType.insert) {
          navigate(
            getWorksheetUrl(operation, EmployeesWorksheetSteps.permissions),
            {
              state: {
                ...currentState,
                fileDictionary,
              },
            },
          );
        } else if (operation === ImportType.update) {
          const { droppedFile, separator } = currentState;
          const payload: CreateImportInput = new CreateImportPayloadBuilder()
            .withActionConfig({
              operation,
            })
            .withFileDictionary(fileDictionary)
            .withSeparator(separator)
            .build(droppedFile);
          await createImportSheet(payload);
          navigate(
            getWorksheetUrl(operation, EmployeesWorksheetSteps.importStatus),
            { state: { refresh: true } },
          );
        }
      }
    },
  });

  useEffect(() => {
    if (initialCell?.cellColumn === 0) return;
    if (!currentState?.hasHeader) {
      const alphabet = [
        'Coluna A',
        'Coluna B',
        'Coluna C',
        'Coluna D',
        'Coluna E',
        'Coluna F',
        'Coluna G',
        'Coluna H',
        'Coluna I',
        'Coluna J',
        'Coluna K',
        'Coluna L',
        'Coluna M',
        'Coluna N',
        'Coluna O',
        'Coluna P',
        'Coluna Q',
        'Coluna R',
        'Coluna S',
        'Coluna T',
        'Coluna U',
        'Coluna V',
        'Coluna W',
        'Coluna X',
        'Coluna Y',
        'Coluna Z',
      ];
      setSelectOptions(alphabet);
    } else {
      const data = Object.assign([], dataTable);
      const selectedOptions = data[initialCell.cellLine - 1];

      setSelectOptions(selectedOptions);
    }
  }, [currentState]);

  const lineValue = useCallback(
    (selectedColumn: number) => {
      const table = Object.assign([], dataTable).slice(0, 4);
      table.splice(
        0,
        currentState.hasHeader
          ? initialCell.cellLine
          : initialCell.cellLine - 1,
      );
      return table.map((e: any, index: number) => {
        return {
          value: e[selectedColumn]?.toLowerCase(),
          index: index,
        };
      });
    },
    [formik.values],
  );

  const optionsValue = useMemo(() => {
    let options = [];
    if (Array.isArray(selectOptions)) {
      options = selectOptions.map((e, index) => {
        return {
          label: e?.toString() || 'Coluna vazia',
          value: { value: e?.toString() || 'emptyColumn', index: index },
        };
      });
    } else {
      options = Object.values(selectOptions).map((e, index) => {
        return {
          label: e?.toString() || 'Coluna vazio',
          value: { value: e?.toString() || 'emptyColumn', index: index },
        };
      });
    }

    return options;
  }, [selectOptions]);

  const emptyColOption = useMemo(() => {
    const options = _.cloneDeep(optionsValue);
    options.push({
      label: 'Não consta',
      value: { value: 'emptyColumn', index: optionsValue.length + 1 },
    });
    return options;
  }, [optionsValue]);

  return (
    <>
      <PageContainer>
        <MainContainer>
          <LeftColumn>
            <StyledTitle
              setColor="secondary50"
              variant="body1"
              children="Associe as informações"
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children="Relacione a coluna do arquivo importado com os campos obrigatórios do nosso cadastro de pessoas."
            />
          </LeftColumn>

          <RightColumn>
            <Card>
              <HeaderContainer>
                <FirstCell>
                  <StyledTitle
                    setColor="neutral20"
                    variant="body3"
                    children="Campos obrigatórios"
                  />
                </FirstCell>
                <SecondCell>
                  <StyledTitle
                    setColor="neutral20"
                    variant="body3"
                    children="Escolha a coluna correspondente"
                  />
                </SecondCell>

                <ThirdCell>
                  <StyledTitle
                    setColor="neutral20"
                    variant="body3"
                    children="Conteúdo da coluna selecionada"
                  />
                </ThirdCell>
              </HeaderContainer>
              {inputList.map((input: FieldInput, index: number) => (
                <LineContainer key={`${input.label}-${index}`}>
                  <FirstCell>{input.label}</FirstCell>
                  <SecondCell>
                    <StyledSelect
                      label="Selecione"
                      options={input.required ? optionsValue : emptyColOption}
                      name={input.value}
                      value={formik.values[input.value]}
                      onSelectChange={(_, e) => {
                        const value = e?.value;
                        e
                          ? setSampleValues((prevState) =>
                              prevState.set(
                                input.value,
                                lineValue(value.index),
                              ),
                            )
                          : setSampleValues((prevState) =>
                              prevState.set(input.value, []),
                            );

                        formik.handleChange({
                          target: {
                            id: input.value,
                            value: value,
                          },
                        });
                      }}
                      error={
                        (formik?.touched?.[input.value] &&
                          Boolean(formik?.errors?.[input.value])) ||
                        contactError
                      }
                      helperText={
                        formik?.touched?.[input.value] &&
                        formik?.errors?.[input.value]?.value
                      }
                    />
                  </SecondCell>
                  {
                    <ThirdCell>
                      <StyledText setColor="neutral20" variant="body3">
                        {sampleValues.get(input.value)?.length
                          ? sampleValues
                              .get(input.value)
                              ?.map((value) =>
                                value?.value
                                  ? `${value.value.toString()}, `
                                  : '',
                              )
                          : ''}
                      </StyledText>
                    </ThirdCell>
                  }
                </LineContainer>
              ))}
              {contactError && (
                <ErrorContainer>
                  <StyledIcon name="IconAlertCircle" fill="none" />
                  <StyledText setColor="neutral20" variant="body3">
                    Não é possível selecionar a mesma coluna para campos
                    obrigatórios diferentes
                  </StyledText>
                </ErrorContainer>
              )}
            </Card>
          </RightColumn>
        </MainContainer>
      </PageContainer>

      <ButtonArea
        onCancel={() => navigate('/employees')}
        onForward={() => formik.handleSubmit()}
        onBackwards={() => {
          const file_type = currentState.fileType;

          if (file_type === 'csv' || file_type === 'txt') {
            navigate(
              getWorksheetUrl(
                operation,
                EmployeesWorksheetSteps.textFileAttributes,
              ),
              {
                state: { ...currentState },
              },
            );
          }
          if (file_type === 'xlsx' || file_type === 'xls') {
            navigate(
              getWorksheetUrl(
                operation,
                EmployeesWorksheetSteps.excelFileAttributes,
              ),
              {
                state: { ...currentState },
              },
            );
          }
        }}
      />
    </>
  );
};
